<template>
  <div id="a-chart-card">
    <div id="a-chart-title">{{ title }}</div>
    <div>
      <slot name="chart"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChartsCard",
  props: {
    title: String,
  },
};
</script>

<style scoped>
#a-chart-card {
  background: white;
  border-radius: 12px;
  border: solid 1px #b2b2b2;
  overflow: scroll;
}

#a-chart-card #a-chart-title {
  background: #f4f3f8;
  border-radius: 12px 12px 0 0;
  border-bottom: solid 1px #b2b2b2;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-1);
  font-size: 12px;
  padding-left: 10px;
  font-weight: bold;
  font-family: Quicksand;
}
</style>
