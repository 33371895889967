<template>
  <main-container>
    <div id="a-outstanding-container">
      <colored-card
        v-for="data in outstandingData"
        :key="data"
        :backgroundColor="data.backgroundColor"
        :textColor="data.textColor"
      >
        <template v-slot:title>
          <h6>{{ data.title }}</h6>
        </template>
        <template v-slot:description>
          <h4>{{ data.description }}</h4>
        </template>
        <template v-slot:icon>
          <img :src="require(`@/assets/icons/${data.icon}.svg`)" />
        </template>
      </colored-card>
    </div>

    <div id="a-charts-container">
      <charts-card v-for="data in chartData" :key="data" :title="data.title">
        <template v-slot:chart>
          <apexchart
            v-if="data.chartData != null"
            height="200"
            :options="data.chartData.chartOptions"
            :series="data.chartData.series"
          ></apexchart>
        </template>
      </charts-card>
    </div>
  </main-container>
</template>

<script>
import ChartsCard from "../components/templates/ChartsCard.vue";
import ColoredCard from "../components/templates/ColoredCard.vue";
import MainContainer from "../components/templates/MainContainer.vue";
import DashboardAPI from "@/services/dashboard.js";
import UserAPI from "@/services/users.js";
export default {
  components: { MainContainer, ColoredCard, ChartsCard },
  data() {
    return {
      outstandingData: {
        users: {
          backgroundColor: "#00d986",
          textColor: "#fff",
          title: "0",
          description: "Usuarios registrados",
          icon: "user-white",
        },
        complaints: {
          backgroundColor: "#ff2f4f",
          textColor: "#fff",
          title: "0",
          description: "Reportes registrados",
          icon: "reports-white",
        },
        solved: {
          backgroundColor: "#ffcd00",
          textColor: "#000",
          title: "0",
          description: "Reportes solucionados",
          icon: "reports-solved",
        },
        top: {
          backgroundColor: "#00b7ff",
          textColor: "#fff",
          title: "",
          description: "Tipo de incidente top",
          icon: "star",
        },
      },
      chartData: {
        incident_type_complaint: {
          title: "Cantidad de reportes por tipo",
          searchKey: "incidentType",
          isSimple: true,
          type: "bar",
          colors: [
            "#00b7ff",
            "#04658b",
            "#007fff",
            "#76beda",
            "#042d3d",
            "#5f76ee",
          ],
          chartData: null,
        },
        status_complaint: {
          title: "Cantidad de reportes por estado",
          searchKey: "step",
          type: "donut",
          chartData: null,
        },
        secretary_complaint: {
          title: "Cantidad de reportes por secretaría",
          searchKey: "secretary",
          isSimple: true,
          type: "bar",
          colors: [
            "#00d986",
            "#77b79e",
            "#00d9c3",
            "#156e4c",
            "#2dff88",
            "#7e7e7e",
          ],
          chartData: null,
        },
        registered_users_type: {
          title: "Cantidad de tipos de usuarios",
          searchKey: "userType",
          type: "donut",
          chartData: null,
        },
        secretary_solved_complaint: {
          title: "Cantidad de reportes por secretaría solucionados",
          isSimple: false,
          params: {
            primaryKey: "secretary_status_complaint",
            signKey: "equal",
            valueKey: "Solucionado",
            filterKey: "step",
            searchKey: "secretary",
          },
          colors: [
            "#ff2f4f",
            "#6a2731",
            "#a80a22",
            "#ff8497",
            "#a37982",
            "#8c8082",
          ],
          type: "bar",
          chartData: null,
        },
        secretary_not_solved_complaint: {
          title: "Cantidad de reportes por secretaría procesados",
          isSimple: false,
          params: {
            primaryKey: "secretary_status_complaint",
            signKey: "different",
            valueKey: "Solucionado",
            filterKey: "step",
            searchKey: "secretary",
          },
          colors: [
            "#ffcd00",
            "#c8ab33",
            "#ffa200",
            "#766729",
            "#ff8000",
            "#f4d96a",
          ],
          type: "bar",
          chartData: null,
        },
      },
    };
  },
  mounted() {
    this.getDataset();
  },
  methods: {
    getDataset() {
      DashboardAPI.index()
        .then((response) => {
          this.setOutstandingCards(response.data);
          this.setCharts(response.data);
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getDataset();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    setOutstandingCards(data) {
      this.outstandingData.users.title = data.registered_users;
      this.outstandingData.complaints.title = data.all_complaint;
      this.outstandingData.solved.title = data.status_complaint.find(
        (element) => element.step.name == "Solucionado"
      ).count;
      this.outstandingData.top.title = data.incident_type_complaint.reduce(
        (p, c) => (p.count > c.count ? p : c)
      ).incidentType.name;
    },
    setCharts(data) {
      Object.entries(this.chartData).forEach(([key, value]) => {
        if (value.type == "bar") {
          if (this.chartData[key].isSimple) {
            this.setBarOptions(data[key], key);
          } else {
            this.setBarOptions(
              data[this.chartData[key].params.primaryKey],
              key
            );
          }
        } else if (value.type == "donut") {
          this.setDonutOptions(data[key], key);
        }
      });
    },
    setBarOptions(data, key) {
      let chartData = {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          labels: [],
          chart: {
            type: "bar",
          },
          plotOptions: {
            bar: {
              distributed: true,
              dataLabels: {
                position: "bottom",
              },
            },
          },
          colors: this.chartData[key].colors,
          xaxis: {
            labels: {
              show: false,
            },
          },
          dataLabels: {
            position: "right",
            enabled: true,
            textAnchor: "start",
            style: {
              colors: ["#fff"],
            },
            offsetX: 0,
            dropShadow: {
              enabled: true,
            },
          },
        },
      };

      if (this.chartData[key].isSimple) {
        data.forEach((element) => {
          chartData.chartOptions.labels.push(
            element[this.chartData[key].searchKey].name
          );
          chartData.series[0].data.push(element.count);
        });

        this.chartData[key].chartData = chartData;
      } else {
        let result = [];
        if (this.chartData[key].params.signKey == "equal") {
          result = data.filter(
            (element) =>
              element[this.chartData[key].params.filterKey].name ==
              "Solucionado"
          );
        } else if (this.chartData[key].params.signKey == "different") {
          let unfilteredData = data.filter(
            (element) =>
              element[this.chartData[key].params.filterKey].name !=
              "Solucionado"
          );

          unfilteredData.forEach((base) => {
            if (result.length == 0) {
              result.push(base);
            } else {
              let indexKey = null;
              result.forEach((result, index) => {
                if (
                  result[this.chartData[key].params.searchKey].id ==
                  base[this.chartData[key].params.searchKey].id
                ) {
                  indexKey = index;
                }
              });
              if (indexKey == null) {
                result.push(base);
              } else {
                result[indexKey].count = result[indexKey].count + base.count;
              }
            }
          });
        }
        result.forEach((element) => {
          chartData.chartOptions.labels.push(
            element[this.chartData[key].params.searchKey].name
          );
          chartData.series[0].data.push(element.count);
        });

        this.chartData[key].chartData = chartData;
      }
    },
    setDonutOptions(data, key) {
      let chartData = {
        series: [],
        chartOptions: {
          labels: [],
          chart: {
            type: "donut",
          },
          colors: [
            "#0b028e",
            "#ae5d00",
            "#00bdea",
            "#00bb37",
            "#5f76ee",
            "#7e7e7e",
          ],
          dataLabels: {
            enabled: true,
            textAnchor: "start",
            style: {
              colors: ["#fff"],
            },
            offsetX: 0,
            dropShadow: {
              enabled: true,
            },
          },
        },
      };

      data.forEach((element) => {
        chartData.chartOptions.labels.push(
          element[this.chartData[key].searchKey].name
        );
        chartData.series.push(element.count);
      });

      this.chartData[key].chartData = chartData;
    },
  },
};
</script>

<style scoped>
#a-outstanding-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -0.5rem;
  gap: 20px;
}
#a-outstanding-container #a-colored-card {
  min-width: 250px;
  min-height: 120px;
  max-height: 120px;
  flex: 1 1 0;
}

#a-charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px -0.5rem;
  gap: 20px;
}

#a-charts-container #a-chart-card {
  min-width: 450px;
  min-height: 250px;
  max-height: 250px;
  flex: 1 1 0;
}
</style>
