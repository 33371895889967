<template>
  <div
    id="a-colored-card"
    :style="`background: ${backgroundColor}; color: ${textColor}`"
  >
    <div>
      <slot name="title"></slot>
      <slot name="description"></slot>
    </div>
    <slot name="icon"></slot>
  </div>
</template>

<script>
export default {
  props: {
    textColor: String,
    backgroundColor: String,
  },
  name: "ColoredCard",
};
</script>

<style>
#a-colored-card img {
  height: 120px;
  width: 60px;
  object-fit: contain;
}
#a-colored-card h6 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

#a-colored-card h4 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.12px;
  margin: 0;
}
</style>

<style scoped>
#a-colored-card {
  display: flex;
  padding: 20px;
  border-radius: 15px;
  justify-content: space-between;
  align-items: center;
}
</style>
